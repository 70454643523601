<script>
import DetailFormGroup from '../../../components/DetailFormGroup'
import api from '@/command/api'
import { getAction, postAction, putAction } from '../../../command/netTool'
import {
  bySubMerTypeList,
  byCertificateTypeList,
  bySubMerClassList,
  bySubMerContactTypeList,
  bySzxcApplyServiceList,
} from '@/utils/textFile'
import moment from 'moment'

export default {
  name: 'supplementDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        certificateNo: null,
        companyCertNo: null,
        subMerType: '1',
        frIsController: '1',
        frIsAgent: '1',
        applyService: [],
      },
      storeData: [],
      industryList: [],
    }
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/commodity/farmShopSubMerchant/detail/${id}`,
        })
        .then((res) => {
          this.detail.applyService = res.applyService ? res.applyService.split(',') : []
        })
    }
    this.storeSearch3()
  },
  methods: {
    async storeSearch3(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmShopSubMerchant/selectSubMerIndustryList?name=${value}`,
      })
      this.industryList = searchResult.map((el) => {
        return {
          name: el.industryName,
          value: el.industryCode,
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '二级商户编号',
            type: 'input',
            cols: 12,
            key: 'subMerId',
            props: {
              disabled: true,
            },
          },
          {
            display: true,
            name: '二级商户名',
            type: 'input',
            cols: 12,
            key: 'subMerName',
            props: {
              disabled: true,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户类型',
            type: 'radioButton',
            cols: 12,
            key: 'subMerType',
            disabled: true,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
            typeData: bySubMerTypeList(),
          },
          {
            display: true,
            name: '补录状态',
            type: 'text',
            cols: 12,
            value: ['未补录', '已补录'][this.detail.appendStatus],
          },
          {
            display: true,
            name: '二级商户经营名称',
            type: 'input',
            cols: 12,
            key: 'subMerchantShortName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.subMerType == '1',
            name: '个人商户类别',
            type: 'select',
            cols: 12,
            key: 'subMerClass',
            typeData: bySubMerClassList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户客服电话',
            type: 'input',
            cols: 12,
            key: 'servicePhone',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户所属行业',
            type: 'select',
            cols: 12,
            key: 'industry',
            typeData: this.industryList,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.subMerType == '1',
            name: '二级商户经营范围',
            type: 'input',
            cols: 12,
            key: 'businessRange',
          },
          {
            display: true,
            name: '二级商户实际经营地址',
            type: 'input',
            cols: 12,
            key: 'address',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '二级商户证件有效期结束时间(yyyyMMdd或‘长期’)',
            type: 'input',
            key: 'endCertificateValidity',
            cols: 12,
            props: {
              maxLength: 8,
            },
          },
          {
            display: true,
            name: '法定代表人/经营者证件有效期开始时间',
            type: 'input',
            key: 'certificateBegDate',
            cols: 12,
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者证件有效期结束时间',
            type: 'input',
            key: 'frCertEndDate',
            cols: 12,
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者证件居住地址',
            type: 'input',
            cols: 12,
            key: 'frResidence',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者是否为受益所有人',
            type: 'radioButton',
            key: 'frIsController',
            cols: 24,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人姓名',
            type: 'input',
            cols: 12,
            key: 'controllerName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件类型',
            type: 'select',
            cols: 12,
            key: 'controllerCertType',
            typeData: byCertificateTypeList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件号码',
            type: 'input',
            cols: 12,
            key: 'controllerCertNo',
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件有效期开始时间',
            type: 'input',
            cols: 12,
            key: 'controllerCertBegDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件有效期结束时间',
            type: 'input',
            cols: 12,
            key: 'controllerCertEndDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsController == '0',
            name: '受益所有人证件居住地址',
            type: 'input',
            cols: 12,
            key: 'controllerResidence',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '法定代表人/经营者是否为实际办理业务人员',
            type: 'radioButton',
            key: 'frIsAgent',
            cols: 24,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员姓名',
            type: 'input',
            cols: 12,
            key: 'agentName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件类型',
            type: 'select',
            cols: 12,
            key: 'agentCertType',
            typeData: byCertificateTypeList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件号码',
            type: 'input',
            cols: 12,
            key: 'agentCertNo',
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件有效期开始时间',
            type: 'input',
            cols: 12,
            key: 'agentCertBegDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件有效期结束时间',
            type: 'input',
            cols: 12,
            key: 'agentCertEndDate',
            props: {
              maxLength: 8,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.frIsAgent == '0',
            name: '授权办理业务人员证件居住地址',
            type: 'input',
            cols: 12,
            key: 'agentResidence',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.subMerType !== '1',
            name: '商户联系人姓名',
            type: 'input',
            cols: 12,
            key: 'subMerContactName',
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.subMerType !== '1',
            name: '商户联系人联系电话',
            type: 'input',
            cols: 12,
            key: 'merMobilePhone',
            rules: [
              {
                ruleType: 'phone',
                required: true,
                type: 'string',
                message: '请输入正确的商户联系人联系电话',
              },
            ],
          },
          {
            display: this.detail.subMerType !== '1',
            name: '商户联系人证件号码',
            type: 'input',
            cols: 12,
            key: 'subMerContactCert',
            props: {
              maxLength: 20,
            },
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: this.detail.subMerType !== '1',
            name: '商户联系人邮箱',
            type: 'input',
            cols: 12,
            key: 'subMerContactMail',
          },
          {
            display: this.detail.subMerType !== '1',
            name: '商户联系人业务标识',
            type: 'select',
            cols: 12,
            key: 'subMerContactType',
            typeData: bySubMerContactTypeList(),
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            display: true,
            name: '申请服务',
            type: 'checkBoxButton',
            key: 'applyService',
            cols: 12,
            typeData: bySzxcApplyServiceList(),
            rules: [
              {
                required: true,
                type: 'array',
                message: '请选择申请服务',
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          onClick: (data) => {
            // if (data.giveStartTime && data.giveEndTime) {
            //   if (!moment(data.giveStartTime).isBefore(data.giveEndTime)) {
            //     this.$message.warning('证件有效期开始时间不能小于证件有效期结束时间!')
            //     return false
            //   }
            // }
            let params = {
              ...data,
              applyService: data.applyService.join(','),
            }
            console.log('params', params)
            // return
            api.command.editPost
              .call(this, {
                url: `/commodity/farmShopSubMerchant/additionalRecording`,
                params: {
                  ...params,
                },
                isPost: false,
              })
              .then(() => {
                this.$router.push('/marketingCenter/secondary')
              })
          },
        },
        {
          name: '取消',
          onClick: () => {
            this.$router.push('/marketingCenter/secondary')
          },
        },
      ]

      return {
        left,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        typeData={{
          shopName: this.storeData,
        }}
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1()]}
        havedrag={true}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
/deep/ .ant-table-content {
  padding: 0 20px;
}
.TableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description_box {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 25px;
  max-height: 25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
